import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import RichText from '../RichText';

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { inViewTriggerThresholds } from '../../animationGlobals';

const TextHero = ({ title, subtitle, variant }) => {

  return (
    <div className={`text-hero text-hero--${variant}`}>
      <div className={'text-hero__inner'}>
        <div className="text-hero__content">
          <h1 className="text-hero__title">{title}</h1>
          {subtitle &&
            (subtitle?.json ? (
              <RichText
                className="text-hero__subtitle"
                source={subtitle.json}
              />
            ) : (
              <p className="text-hero__subtitle">{subtitle}</p>
            ))}
        </div>{' '}
      </div>
    </div>
  );
};

TextHero.propTypes = {
  variant: PropTypes.oneOf(['left-aligned', 'centered']),
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

TextHero.defaultProps = {
  variant: 'centered',
  title: 'Hero title',
  subtitle: null,
};

export default TextHero;
